import axios from "axios";

export const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

apiClient.interceptors.request.use(
  (config) => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      const user = JSON.parse(storedUser);
      if (user.tokens && user.tokens.AccessToken) {
        config.headers.Authorization = `Bearer ${user.tokens.AccessToken}`;
      }
    } else {
      if (window.location.pathname !== "/") {
        window.location.href = "/";
      }
    }

    // Get the subdomain from the hostname
    const hostname = window.location.hostname;
    const subdomain = hostname.split(".")[0]; // Get the subdomain part of the hostname

    // Special case for 'james'
    let formattedSubdomain =
      subdomain === "james"
        ? "uy-james"
        : subdomain.startsWith("demo")
        ? subdomain.replace("demo", "demo-")
        : subdomain;

    // Set the Client header with the formatted subdomain
    config.headers["Client"] = formattedSubdomain;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const getData = async (endpoint, params = {}) => {
  try {
    const response = await apiClient.get(endpoint, { params });
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const postData = async (endpoint, data) => {
  try {
    const response = await apiClient.post(endpoint, data);
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};

export const postPhoto = async (endpoint, photo, text) => {
  try {
    if (!(photo instanceof File || photo instanceof Blob)) {
      throw new Error("Invalid photo object. Expected a File or Blob.");
    }

    const resizedPhoto = await resizeImage(photo, 1280, 1280);

    const formData = new FormData();
    formData.append("image", resizedPhoto);
    formData.append("ocr_text", text);

    const response = await apiClient.post(endpoint, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error posting photo:", error);
    throw error;
  }
};

const resizeImage = (file, maxWidth, maxHeight) => {
  return new Promise((resolve, reject) => {
    if (!(file instanceof File || file instanceof Blob)) {
      reject(new Error("Invalid file input. Must be a File or Blob."));
      return;
    }

    const img = new Image();
    img.src = URL.createObjectURL(file);

    img.onload = () => {
      let width = img.width;
      let height = img.height;

      // Calculate the new dimensions while maintaining aspect ratio
      if (width > height) {
        if (width > maxWidth) {
          height = Math.round((height *= maxWidth / width));
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width = Math.round((width *= maxHeight / height));
          height = maxHeight;
        }
      }

      // Create a canvas to resize the image
      const canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;

      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, width, height);

      canvas.toBlob(
        (blob) => {
          resolve(
            new File([blob], file.name, {
              type: file.type,
              lastModified: Date.now(),
            })
          );
        },
        file.type,
        0.95 // Image quality (optional, ranges from 0 to 1)
      );
    };

    img.onerror = (error) => reject(error);
  });
};

export const patchData = async (endpoint, data) => {
  try {
    const response = await apiClient.patch(endpoint, data);
    return response.data;
  } catch (error) {
    console.error("Error patching data:", error);
    throw error;
  }
};
