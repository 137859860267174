import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import modelsInfo from "../../data/supported_models.json";
import { useParams } from "react-router-dom";

const useConfirmationLogic = ({
  detection,
  onConfirm,
  setConfirmedByUser,
  setErrorMessage,
  onClose,
}) => {
  const { chat_id } = useParams();
  const [deviceType, setDeviceType] = useState(
    detection?.device_type === "unknown"
      ? ""
      : detection?.device_type?.split("type-")[1]
  );
  const deviceBrand = "James";

  const getModelList = () => {
    const loweredDeviceBrand = deviceBrand.toLowerCase();
    if (
      modelsInfo[loweredDeviceBrand] &&
      modelsInfo[loweredDeviceBrand][deviceType]
    ) {
      return { ...modelsInfo[loweredDeviceBrand][deviceType] };
    }
    return {};
  };
  const modelList = getModelList();

  let deviceModelFromDetection = [""];
  if (detection?.device_model) {
    if (Array.isArray(detection?.device_model)) {
      deviceModelFromDetection = detection?.device_model
        .map((model) => {
          if (
            Object.values(modelList).indexOf(model) !== -1 ||
            model.startsWith("unsupported-")
          ) {
            return model;
          }
          return `unsupported-${model}`;
        })
        .filter((x) => x.toLowerCase().indexOf("unknown") === -1);
    } else {
      deviceModelFromDetection = [detection?.device_model];
    }
  }
  const [deviceModels, setDeviceModels] = useState(modelList);
  const [deviceModel, setDeviceModel] = useState(deviceModelFromDetection);
  const [deviceTypes, setDeviceTypes] = useState(["LÍNEA DE LAVADO"]);

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/") {
      setDeviceType(null);
      setDeviceModel(null);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (deviceBrand.length === 0) {
      setDeviceModels([]);
      return;
    }
    setDeviceModels(getModelList());
  }, [deviceBrand, deviceType]);

  const handleConfirm = async () => {
    if (!deviceType || deviceType.length === 0) {
      setErrorMessage("Debe seleccionar un tipo de dispositivo");
      return;
    }
    if (deviceTypes.indexOf(deviceType) === -1) {
      setErrorMessage(
        "Lo siento, actualmente no soportamos este tipo de dispositivo."
      );
      return;
    }

    if (deviceBrand.toLocaleLowerCase() !== "james") {
      setErrorMessage("Solo soportamos modelos de la marca James");
      return;
    }

    if (deviceModel[0].length === 0) {
      setErrorMessage("Debe seleccionar un tipo de modelo");
      return;
    }
    if (Object.values(deviceModels).indexOf(deviceModel[0]) === -1) {
      setErrorMessage("Lo siento, actualmente no soportamos este modelo.");
      return;
    }

    setErrorMessage("");
    await onConfirm(
      chat_id,
      {
        device_type: deviceType ? deviceType : "unknown",
        device_brand: deviceBrand ? deviceBrand : "unknown",
        device_model: deviceModel.length >= 1 ? [deviceModel[0]] : [],
      },
      true
    );
    setConfirmedByUser(true);
    if (onClose) {
      onClose();
    }
  };

  const handleModelSelection = (model_text) => {
    if (Object.values(deviceModels).indexOf(model_text) !== -1) {
      const newList = [...new Set([model_text, ...deviceModel])].filter(
        (x) => x.length > 0
      );
      setDeviceModel(newList);
    }
  };

  return {
    deviceType,
    setDeviceType,
    deviceBrand,
    deviceModel,
    deviceTypes,
    deviceModels,
    handleConfirm,
    handleModelSelection,
  };
};

export default useConfirmationLogic;
