import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getData, postData, postPhoto, patchData } from "../../Api";
import { compareDeviceInfo } from "../../utils";
import { performOCR } from "../../Ocr";

const useMainContainerLogic = () => {
  const [user, setUser] = useState();
  const [photo, setPhoto] = useState();
  const [deviceInfo, setDeviceInfo] = useState();
  const [stateMetadata, setStateMetadata] = useState();
  const [photoId, setPhotoId] = useState();
  const [confirmedByUser, setConfirmedByUser] = useState(false);
  const [chatId, setChatId] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessageColor, setErrorMessageColor] = useState("red");
  const navigate = useNavigate();
  const location = useLocation();
  let deviceGlossary = null;

  const hostname = window.location.hostname;
  const subdomain = hostname.split(".")[0];

  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("warningDisplayed");
    setUser(null);
    navigate("/");
  };

  const loginCall = async (email, password) => {
    const data = { email };
    if (password !== null) {
      data["password"] = password;
    }
    try {
      const response = await postData("/login_signup", data);
      localStorage.setItem("user", JSON.stringify(response));
      setUser(response);
      setErrorMessage("");
      navigate("/chat/not-started");
    } catch (error) {
      if (error?.response?.data?.error) {
        setErrorMessage(error?.response?.data?.error);
      } else {
        setErrorMessage("Error desconocido intente nuevamente.");
      }
    }
  };

  const detectionCall = async (current_photo, silent = false) => {
    setLoading(true);
    try {
      const ocrText = await performOCR(current_photo);

      const response = await postPhoto(
        `/chats/not-started/messages/image`,
        current_photo,
        ocrText
      );

      const detection = {
        device_type:
          response.chat.state_metadata.state["detection.device_type"],
        device_brand:
          response.chat.state_metadata.state["detection.device_brand"],
        device_model:
          response.chat.state_metadata.state["detection.device_model"],
      };

      const info = { ...detection };
      for (let key in info) {
        if (!info[key] || info[key] === "unknown") {
          info[key] = null;
          if (silent) {
            console.log(
              "Some value is empty from the detection in silent mode"
            );
            return false;
          }
        } else if (key === "device_model") {
          if (silent && info[key].length > 1) {
            console.log("The detection is not certain about device model");
            return false;
          }
        }
      }

      setPhoto(current_photo);
      setConfirmedByUser(false);
      setDeviceInfo(detection);
      setStateMetadata({ ...response.chat.state_metadata });
      deviceGlossary = null;
      setPhotoId(response.image_id);
      setErrorMessage("");
      navigate(`/confirm/${response.chat.chat_id}`);
      setLoading(false);
      return true;
    } catch (error) {
      setErrorMessage(
        "Hubo un error durante la detección. Por favor, intentá de nuevo."
      );
    }
    setLoading(false);
    return false;
  };

  const userFeedbackCall = async (
    chat_id,
    userDeviceInfo,
    redirect = false
  ) => {
    setLoading(true);
    try {
      if (!compareDeviceInfo(deviceInfo, userDeviceInfo)) {
        const imageId = chat_id ? chat_id : "no-photo";
        if (imageId === "no-photo") {
          setConfirmedByUser(true);
        }
        const newState = { ...stateMetadata };
        newState["current_state_id"] = "3";
        newState["state"] = {
          ...newState["state"],
          "detection.device_type": userDeviceInfo.device_type,
          "detection.device_brand": userDeviceInfo.device_brand,
          "detection.device_model": userDeviceInfo.device_model,
        };
        const response = await patchData(`/chats/${chat_id}`, {
          state_metadata: newState,
          process_message: true,
        });
        setDeviceInfo({ ...userDeviceInfo });
        deviceGlossary = null;
        if (redirect) {
          setChatId(response.chat_id);
          navigate(`/chat/${response.chat_id}`);
        }
      }
    } catch (error) {
      setErrorMessage(
        "Hubo un error durante la creacion del chat. Por favor, intentá de nuevo."
      );
    }
    setLoading(false);
  };

  const sendMessageCall = async (chat_id, message, onData) => {
    try {
      const data = await postData(`/chats/${chat_id}/messages`, {
        message,
      });
      onData(data);
    } catch (error) {
      setErrorMessage(
        "Hubo un error durante la detección. Por favor, intentá de nuevo."
      );
    }
  };
  const sendAudioCall = async (chat_id, audioBlob, onData) => {
    try {
      const storedUser = localStorage.getItem("user");
      const headers = new Headers();

      if (storedUser) {
        const user = JSON.parse(storedUser);
        if (user.tokens && user.tokens.AccessToken) {
          headers.append("Authorization", `Bearer ${user.tokens.AccessToken}`);
        }
      }

      const formData = new FormData();
      formData.append("audio", audioBlob, "audio.wav");

      const data = await postData(`/chats/${chat_id}/messages/audio`, formData);
      onData(data);
    } catch (error) {
      setErrorMessage(
        "Hubo un error durante la detección. Por favor, intentá de nuevo."
      );
    }
  };

  const getDeviceGlossary = async (deviceInfo) => {
    if (deviceGlossary !== null) {
      return deviceGlossary;
    }
    try {
      const data = await getData("/device/info", deviceInfo);
      deviceGlossary = data.information;
    } catch (error) {}
    return deviceGlossary;
  };

  const handleErrorMessage = (message = "", color = "red") => {
    setErrorMessage(message);
    setErrorMessageColor(color);
  };

  useEffect(() => {
    const photoSubmit = async () => {
      if (photo) {
        setErrorMessage("");
        setLoading(true);
        await detectionCall(photo);
        setPhoto(null);
        setLoading(false);
      }
    };
    photoSubmit();
  }, [photo]);

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
    if (location.pathname === "/") {
      if (storedUser) {
        navigate("/chat/not-started");
      } else {
        loginCall("noemail@noemail.com", null);
      }
    }
  }, [navigate]);

  return {
    user,
    photo,
    deviceInfo,
    photoId,
    confirmedByUser,
    chatId,
    errorMessage,
    errorMessageColor,
    handleLogout,
    loginCall,
    detectionCall,
    userFeedbackCall,
    sendMessageCall,
    sendAudioCall,
    getDeviceGlossary,
    handleErrorMessage,
    setConfirmedByUser,
    loading,
    setLoading,
    photo,
    setPhoto,
  };
};

export default useMainContainerLogic;
