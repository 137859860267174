import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import { patchData } from "../../Api";
import { DescTypograhpy } from "../GlobalStyles";
import DeviceCardComponent from "../DeviceCard/DeviceCardView";
import useConfirmationLogic from "./ConfirmationLogic";
import {
  ConfirmationContainer,
  ConfirmationParagraph,
  ImageContainer,
  DevicePhoto,
  ConfirmationButton,
  ModelImages,
  ModelContainer,
  ModelImage,
  ModelLabel,
} from "./ConfirmationStyles";

import { getModelImageSrc } from "../../utils";

const ConfirmationView = ({
  photoId,
  detection,
  onConfirm,
  onClose = null,
  setConfirmedByUser,
  setErrorMessage,
}) => {
  const {
    deviceType,
    setDeviceType,
    deviceBrand,
    deviceModel,
    deviceTypes,
    deviceModels,
    handleConfirm,
    handleModelSelection,
  } = useConfirmationLogic({
    detection,
    onConfirm,
    setConfirmedByUser,
    setErrorMessage,
    onClose,
  });
  const navigate = useNavigate();
  const { chat_id } = useParams();
  const [isConfirming, setIsConfirming] = useState(false);
  const [showForm, setShowForm] = useState(
    chat_id === "no-photo" || !deviceType || !deviceModel
  );
  const handleConfirmClick = async () => {
    setIsConfirming(true);
    await handleConfirm();
    setIsConfirming(false);
  };

  const handleNoClick = () => {
    setShowForm(true);
  };

  useEffect(() => {
    if (deviceType && !deviceModel.length) {
      setErrorMessage(
        "¡Ups! No pudimos detectar el tipo de modelo. Por favor, selecciona el tipo de modelo manualmente."
      );
    }

    if (!deviceType || !deviceModel) {
      setErrorMessage(
        "¡Ups! No pudimos detectar el tipo de modelo. Por favor, selecciona el tipo de modelo manualmente."
      );
    }
  }, []);

  const otherModels = deviceModel
    ? deviceModel.filter(
        (x) =>
          ["unsupported-unknown", "unknown"].indexOf(x.toLowerCase()) === -1
      )
    : [];

  return (
    <ConfirmationContainer>
      {!showForm &&
      deviceModel &&
      Array.isArray(deviceModel) &&
      deviceModel.length >= 1 ? (
        <>
          <ConfirmationParagraph>
            Se detectó {deviceType} {deviceBrand} modelo {deviceModel[0]}
          </ConfirmationParagraph>
          <ImageContainer>
            <DevicePhoto
              src={getModelImageSrc(deviceModel[0])}
              alt="Dispositivo"
            />
          </ImageContainer>

          <ConfirmationParagraph>¿Es correcto?</ConfirmationParagraph>
          <Box display="flex" justifyContent="center" margin="20px 0">
            <ConfirmationButton
              variant="outlined"
              color="primary"
              onClick={handleNoClick}
              style={{
                marginRight: "10px",
                background: "transparent",
                color: "black",
                borderColor: "black",
              }}
            >
              No
            </ConfirmationButton>
            <ConfirmationButton
              onClick={handleConfirmClick}
              disabled={isConfirming}
            >
              Sí
            </ConfirmationButton>
          </Box>
          {otherModels.length >= 2 && (
            <div style={{ marginBottom: "5vh" }}>
              <Typography variant="h6" style={{ margin: "20px 0" }}>
                Otras opciones similares:
              </Typography>
              <ModelImages>
                {[...otherModels.slice(1, otherModels.length)]
                  .sort()
                  .map((model) => (
                    <ModelContainer key={model}>
                      <ModelImage
                        src={getModelImageSrc(model)}
                        alt={model}
                        onClick={() => handleModelSelection(model)}
                        selected={deviceModel[0] === model}
                      />
                      <ModelLabel>{model}</ModelLabel>
                    </ModelContainer>
                  ))}
              </ModelImages>
            </div>
          )}
        </>
      ) : (
        <>
          <DescTypograhpy style={{ marginBottom: "20px" }}>
            Completa los datos de tu dispositivo
          </DescTypograhpy>
          <Box width="100%" mb={2}>
            <FormControl fullWidth>
              <InputLabel htmlFor="deviceType">Tipo de dispositivo</InputLabel>
              <Select
                id="deviceType"
                value={deviceType}
                onChange={(e) => setDeviceType(e.target.value)}
                label="Tipo de dispositivo"
              >
                <MenuItem value="">
                  <em>Seleccionar tipo</em>
                </MenuItem>
                {deviceTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box width="100%" mb={2}>
            <FormControl fullWidth>
              <InputLabel htmlFor="deviceModel">
                Modelo del dispositivo
              </InputLabel>
              <Select
                id="deviceModel"
                value={deviceModel[Object.keys(deviceModels)[0]]}
                onChange={(e) => handleModelSelection(e.target.value)}
                label="Modelo del dispositivo"
              >
                <MenuItem value="">
                  <em>Seleccionar modelo</em>
                </MenuItem>
                {Object.keys(deviceModels).map((key) => (
                  <MenuItem key={key} value={deviceModels[key]}>
                    {key}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <ConfirmationButton
            onClick={handleConfirmClick}
            disabled={isConfirming}
          >
            Ir al chat
          </ConfirmationButton>
          <ConfirmationButton
            onClick={async () => {
              const response = await patchData(`/chats/${chat_id}`, {
                process_message: true,
                trigger_fail: true,
              });
              navigate(`/chat/${response["chat_id"]}`);
            }}
            disabled={isConfirming}
            sx={{
              backgroundColor: "grey",
              "&:hover": {
                backgroundColor: "black",
              },
            }}
          >
            Mi electrodoméstico no esta en la lista
          </ConfirmationButton>
          {deviceModel &&
            Array.isArray(deviceModel) &&
            deviceModel.length >= 1 && (
              <DeviceCardComponent
                imageSrc={getModelImageSrc(deviceModel[0])}
                altText={deviceModel[0]}
                text={`${deviceType ? deviceType : ""} ${
                  deviceBrand ? deviceBrand : ""
                } Modelo ${deviceModel[0]}`}
              />
            )}
        </>
      )}
    </ConfirmationContainer>
  );
};

export default ConfirmationView;
