import React, { useRef, useEffect, useState } from "react";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  CameraAlt,
  Upload,
  PanToolAlt,
  ContentCopy,
  Mic,
  Stop,
  Send,
  FileUpload,
  Image as ImageIcon,
  VolumeUp,
} from "@mui/icons-material";
import {
  Box,
  IconButton,
  Typography,
  Button,
  Switch,
  FormControlLabel,
} from "@mui/material";
import useChatLogic from "./ChatLogic";
import TypingMessage from "./TypingMessage";
import {
  ChatContainer,
  MessagesContainer,
  MessageBox,
  MessageIcon,
  MessageText,
  CopyButton,
  InputContainer,
  TopSectionContainerSubtitle,
  StyledInputBase,
  SidebarContainer,
  TopSectionContainer,
  ActionButton,
} from "./ChatStyles";
import { postData } from "../../Api";

const ChatView = ({
  deviceInfo,
  sendMessage,
  sendAudio,
  getDeviceGlossary,
  confirmedByUser,
  setConfirmedByUser,
  setErrorMessage,
  photo,
  setPhoto,
}) => {
  const {
    messages,
    input,
    setInput,
    handleSend,
    toggleRightSidebar,
    showRightSidebarReal,
    deviceInfoContent,
    placeholder,
    loading,
    handleUploadAudio,
    handleUploadImage,
    handleUploadFile,
  } = useChatLogic(
    sendMessage,
    sendAudio,
    getDeviceGlossary,
    confirmedByUser,
    setConfirmedByUser,
    setErrorMessage,
    deviceInfo
  );

  const [recording, setRecording] = useState(false);
  const { chat_id } = useParams();
  const [autoRead, setAutoRead] = useState(false); // Toggle state
  const audioRefs = useRef({}); // Store audio references to avoid re-fetching
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [audioContext, setAudioContext] = useState(null);
  const [audioHistory, setAudioHistory] = useState([]);
  const [timeLeft, setTimeLeft] = useState(30);
  const canvasRef = useRef(null);
  const messagesEndRef = useRef(null);
  const audioContextClosed = useRef(false);
  const navigate = useNavigate();
  const location = useLocation();
  const fileInputRef = useRef(null);
  const imageInputRef = useRef(null);

  const handleFileInputChange = (e) => handleUploadFile(e.target.files[0]);
  const handleImageInputChange = (e) => handleUploadImage(e.target.files[0]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (!canvasRef.current) return;
    if (!audioHistory) return;
    const canvas = canvasRef.current;
    const canvasCtx = canvas.getContext("2d");
    const width = canvas.width;
    const height = canvas.height;
    canvasCtx.clearRect(0, 0, width, height);
    canvasCtx.lineWidth = 2;
    canvasCtx.strokeStyle = "#0033a0";

    canvasCtx.beginPath();
    const sliceWidth = (width * 1.0) / audioHistory.length;
    let x = 0;
    for (let i = 0; i < audioHistory.length; i++) {
      const v = audioHistory[i] / 128.0;
      const y = (v * height) / 2;
      if (i === 0) {
        canvasCtx.moveTo(x, y);
      } else {
        canvasCtx.lineTo(x, y);
      }
      x += sliceWidth;
    }
    canvasCtx.lineTo(canvas.width, canvas.height / 2);
    canvasCtx.stroke();
  }, [audioHistory]);

  const handleStartRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
      const analyserNode = audioCtx.createAnalyser();
      analyserNode.fftSize = 2048;
      const source = audioCtx.createMediaStreamSource(stream);
      source.connect(analyserNode);
      setAudioContext(audioCtx);
      setAudioHistory([]);
      audioContextClosed.current = false;

      const recorder = new MediaRecorder(stream);
      setMediaRecorder(recorder);

      let recorderIsPlaying = true;

      recorder.ondataavailable = (event) => {
        const audioBlob = event.data;
        recorderIsPlaying = false;
        handleUploadAudio(audioBlob);
      };

      recorder.start();
      setRecording(true);

      let timer = 30;
      setTimeLeft(timer);
      const timeoutTimer = () => {
        timer -= 1;
        setTimeLeft(timer);
        if (timer <= 0 || !recorderIsPlaying) {
          recorder.stop();
          setRecording(false);
        } else {
          setTimeout(timeoutTimer, 1000);
        }
      };
      timeoutTimer();

      const draw = () => {
        if (!canvasRef.current || !analyserNode) return;
        const canvas = canvasRef.current;
        const width = canvas.width;
        const dataArray = new Uint8Array(analyserNode.frequencyBinCount);
        analyserNode.getByteTimeDomainData(dataArray);

        setAudioHistory((prevHistory) => {
          const newHistory = [...prevHistory, ...Array.from(dataArray)];
          if (newHistory.length > width) {
            newHistory.splice(newHistory.length - width, newHistory.length);
          }
          return [...newHistory];
        });
        requestAnimationFrame(draw);
      };
      draw();
    } catch (err) {
      console.error("Error starting audio recording:", err);
    }
  };

  const handleStopRecording = () => {
    mediaRecorder.stop();
    setRecording(false);
    if (audioContext && !audioContextClosed.current) {
      audioContext.close();
      audioContextClosed.current = true;
    }
  };

  const inputFileRef = useRef(null);

  const handleFileClick = () => {
    inputFileRef.current.click();
  };

  const handlePhotoChangeWithLoading = (e) => {
    if (!photo) {
      setPhoto(e.target.files[0]);
    }
  };

  const hostname = window.location.hostname;
  const subdomain = hostname.split(".")[0];

  const logoChicoSrc =
    subdomain === "james"
      ? `${process.env.PUBLIC_URL}/james_logo_chico.png`
      : `${process.env.PUBLIC_URL}/manuar-icon.png`;

  const handlePlayAudio = async (text, messageId) => {
    if (audioRefs.current[messageId]) {
      // If audio already fetched, play it
      audioRefs.current[messageId].play();
      return;
    }

      // Make the request to the updated TTS endpoint
      const response = await postData(
      `/chats/${chat_id}/messages/${messageId}/tts`, // Include chat_id in the path
        { message: text }
      );

    const { audio_url: audioUrl } = response;

      // Create and play the audio
      const audio = new Audio(audioUrl);
      audioRefs.current[messageId] = audio; // Cache the audio for the message
      audio.play();
  };

  useEffect(() => {
    if (autoRead) {
      const latestMessage = messages[messages.length - 1];
      if (latestMessage && latestMessage.complete) {
        handlePlayAudio(latestMessage.message, latestMessage.id);
      }
    }
  }, [messages, autoRead]);

  const toggleAutoRead = () => {
    setAutoRead((prev) => !prev);
  };

  return (
    <>
      <ChatContainer>
        {location.pathname === "/chat/not-started" && subdomain == "james" && (
          <TopSectionContainer
            sx={{ paddingBottom: "10vh", paddingTop: "5vh" }}
          >
            <Typography variant="h6">Iniciar conversación</Typography>
            <TopSectionContainerSubtitle
              variant="body2"
              sx={{ marginBottom: "2vh", marginTop: "2vh" }}
            >
              Elige una de las opciones o escribe tu consulta directamente en el
              chat.
            </TopSectionContainerSubtitle>
            <ActionButton
              variant="contained"
              startIcon={<CameraAlt />}
              onClick={() => navigate("/upload")}
              sx={{ marginBottom: "2vh", marginTop: "2vh" }}
            >
              Tomar foto
            </ActionButton>
            <ActionButton
              variant="contained"
              startIcon={<Upload />}
              onClick={handleFileClick}
              sx={{ marginBottom: "2vh", marginTop: "2vh" }}
            >
              <input
                type="file"
                ref={inputFileRef}
                onChange={handlePhotoChangeWithLoading}
                accept="image/*"
                hidden
              />
              Subir imagen
            </ActionButton>
            <ActionButton
              variant="contained"
              startIcon={<PanToolAlt />}
              sx={{ marginBottom: "2vh", marginTop: "2vh" }}
              onClick={() => navigate("/confirm/no-photo")}
            >
              Cargar manualmente
            </ActionButton>
          </TopSectionContainer>
        )}
        <TopSectionContainer>
          <FormControlLabel
            control={<Switch checked={autoRead} onChange={toggleAutoRead} />}
            label="Lectura automática de mensajes"
          />
        </TopSectionContainer>
        <MessagesContainer>
          {messages.map((msg, index) => {
            if (!msg.message) {
              msg.message = "";
            }
            const parts = msg.message.split("[new_message]");
            return (
              <React.Fragment key={index}>
                {parts.map((part, idx) => (
                  <MessageBox key={`${index}-${idx}`} sender={msg.sender}>
                    <MessageIcon>
                      <img
                        src={
                          msg.sender === "user"
                            ? `${process.env.PUBLIC_URL}/user.png`
                            : logoChicoSrc
                        }
                        alt="icon"
                      />
                    </MessageIcon>
                    <MessageText sender={msg.sender}>
                      {(msg.attachments ?? []).length > 0 && (
                        <Box mt={1}>
                          {msg.attachments.map(
                            (attachment, attachmentIndex) => (
                              <React.Fragment key={attachmentIndex}>
                                {attachment.type === "image" && (
                                  <img
                                    src={attachment.url}
                                    alt="attachment"
                                    style={{
                                      maxWidth: "100%",
                                      marginTop: "8px",
                                      maxHeight: "50vh",
                                    }}
                                  />
                                )}
                                {attachment.type === "audio" && (
                                  <Box mt={1}>
                                    <audio controls src={attachment.url}>
                                      Your browser does not support the audio
                                      element.
                                    </audio>
                                    {part && (
                                      <p>
                                        <strong>Transcripción:</strong>
                                      </p>
                                    )}
                                  </Box>
                                )}
                                {!attachment.type.startsWith("image") &&
                                  !attachment.type.startsWith("audio") && (
                                    <Box mt={1}>
                                      <Button
                                        variant="outlined"
                                        component="a"
                                        href={attachment.url}
                                        download={
                                          attachment.filename || "archivo"
                                        }
                                      >
                                        Descarga{" "}
                                        {attachment.filename || "Archivo"}
                                      </Button>
                                    </Box>
                                  )}
                              </React.Fragment>
                            )
                          )}
                        </Box>
                      )}
                      {part === "..." ? (
                        placeholder
                      ) : msg.sender == "user" &&
                        (msg.attachments ?? []).length > 0 &&
                        msg.attachments[0].type === "image" &&
                        index === 0 ? (
                        <></>
                      ) : (
                        <TypingMessage
                          message={part}
                          complete={msg.complete || msg.sender === "user"}
                        />
                      )}
                      <Box display="flex" alignItems="center">
                        <CopyButton
                          onClick={() => navigator.clipboard.writeText(part)}
                          sender={msg.sender}
                        >
                          <ContentCopy
                            fontSize="small"
                            style={{ color: "gray" }}
                          />
                        </CopyButton>
                        <IconButton
                          onClick={() => handlePlayAudio(part, msg.id)}
                        >
                          <VolumeUp fontSize="small" />
                        </IconButton>
                      </Box>
                    </MessageText>
                  </MessageBox>
                ))}
              </React.Fragment>
            );
          })}
          <div ref={messagesEndRef} />
        </MessagesContainer>
        <InputContainer>
          {recording && (
            <div
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <canvas
                ref={canvasRef}
                width="500"
                height="50"
                style={{
                  width: "90%",
                  height: "50px",
                  backgroundColor: "#f2f2f2",
                }}
              />
              <Typography
                variant="h6"
                color="textSecondary"
                style={{ marginLeft: "10px" }}
              >
                {timeLeft}s
              </Typography>
            </div>
          )}
          {!recording && (
            <>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileInputChange}
              />
              <IconButton
                onClick={() => fileInputRef.current.click()}
                disabled={loading}
              >
                <FileUpload />
              </IconButton>
              <input
                type="file"
                ref={imageInputRef}
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleImageInputChange}
              />
              <IconButton
                onClick={() => imageInputRef.current.click()}
                disabled={loading}
              >
                <ImageIcon />
              </IconButton>
              <StyledInputBase
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder="Escribe tu mensaje..."
              />
              <IconButton onClick={handleSend} disabled={loading || !input}>
                <Send />
              </IconButton>
            </>
          )}
          {!recording && input.length > 0 && (
            <IconButton onClick={handleSend} disabled={loading}>
              <Send style={{ color: "black" }} />
            </IconButton>
          )}
          {input.length === 0 && (
            <IconButton
              onClick={recording ? handleStopRecording : handleStartRecording}
              disabled={loading}
            >
              {recording ? (
                <Stop style={{ color: "red" }} />
              ) : (
                <Mic style={{ color: "black" }} />
              )}
            </IconButton>
          )}
        </InputContainer>
      </ChatContainer>
      <SidebarContainer
        position="right"
        visible={showRightSidebarReal.toString()}
      >
        <IconButton
          sx={{ position: "absolute", top: "10px", right: "10px" }}
          onClick={() => toggleRightSidebar(false)}
        >
          &times;
        </IconButton>
        <Box className="device-info">
          <Markdown className="markdown-table" remarkPlugins={[remarkGfm]}>
            {deviceInfoContent}
          </Markdown>
        </Box>
      </SidebarContainer>
    </>
  );
};

export default ChatView;
