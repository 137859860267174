import { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getData, postData, postPhoto } from "../../Api";

const useChatLogic = (
  sendMessage,
  sendAudio,
  getDeviceGlossary,
  confirmedByUser,
  setConfirmedByUser,
  setErrorMessage,
  deviceInfo
) => {
  const navigate = useNavigate();
  const { chat_id } = useParams();
  const [messages, setMessages] = useState([]);

  const [input, setInput] = useState("");
  const [showLeftSidebar, setShowLeftSidebar] = useState(false);
  const [showRightSidebar, setShowRightSidebar] = useState(false);
  const [deviceInfoContent, setDeviceInfoContent] = useState("");
  const [placeholder, setPlaceholder] = useState(".");
  const [loading, setLoading] = useState(false);

  const pollingRef = useRef(false);
  const messageBufferRef = useRef("");
  const typingIndexRef = useRef(0);
  const typingIntervalRef = useRef(null);
  const pollingIntervalRef = useRef(null);

  const handleResponse = (data) => {
    if (chat_id === "not-started") {
      navigate(`/chat/${data["chat_id"]}`);
    }
    setLoading(false);
  };

  const handleSendMessage = (message, sendFunc, audioBlob = null) => {
    const formattedMessage = message.trim();
    messageBufferRef.current = "";
    clearInterval(typingIntervalRef.current);

    if (formattedMessage) {
      setLoading(true);
      pollingRef.current = true;
      messageBufferRef.current = "";
      typingIndexRef.current = 0;

      let response;
      let processedMessage = audioBlob ? "[Audio message]" : formattedMessage;
      if (audioBlob) {
        response = sendFunc(chat_id, audioBlob, (answer) =>
          handleResponse(answer, formattedMessage)
        );
      } else {
        response = sendFunc(chat_id, formattedMessage, handleResponse);
      }

      setMessages((prevMessages) => [
        ...prevMessages,
        {
          id: `user-message-${Date.now()}`,
          sender: "user",
          message: processedMessage,
        },
        {
          id: `ai-typing-${Date.now()}`,
          sender: "ai",
          message: "...",
        },
      ]);

      setInput("");
    }
  };
  const handleSend = () => handleSendMessage(input, sendMessage);
  const handleUploadAudio = (audioBlob) =>
    handleSendMessage("[Audio message]", sendAudio, audioBlob);

  const pollForNewMessages = async () => {
    try {
      const data = await getData(`/chats/${chat_id}`);
      const newMessages = data.messages;

      if (newMessages.length > 0) {
        const newListOfMessages = [...newMessages];

        // Function to compare two arrays of objects
        const areMessagesDifferent = (arr1, arr2) => {
          if (arr1.length !== arr2.length) return true;
          for (let i = 0; i < arr1.length; i++) {
            if (
              arr1[i].id !== arr2[i].id ||
              arr1[i].message !== arr2[i].message
            ) {
              return true;
            }
          }
          return false;
        };

        setMessages((prevMessages) => {
          if (
            areMessagesDifferent(prevMessages, newListOfMessages) &&
            newListOfMessages.length >= prevMessages.length
          ) {
            return newListOfMessages;
          }
          return prevMessages;
        });
      }
    } catch (error) {
      console.error("Polling error:", error);
    }
  };
  useEffect(() => {
    const startPolling = () => {
      pollingIntervalRef.current = setInterval(() => {
        pollForNewMessages();
      }, 1500); // Adjust the interval as needed
    };

    const getChatMessages = async () => {
      const response = await getData(`/chats/${chat_id}`);
      const newMessages = [];
      for (const m in response["messages"]) {
        const message = response["messages"][m];
        newMessages.push(message);
      }

      // Filter out duplicates on initial load
      const filteredMessages = newMessages.filter(
        (msg) => !messages.some((existingMsg) => existingMsg.id === msg.id)
      );

      setMessages(filteredMessages);
    };

    if (chat_id !== "not-started") {
      getChatMessages();
      startPolling();
    }

    const interval = setInterval(() => {
      setPlaceholder((prev) => (prev === "..." ? "." : prev + "."));
    }, 500);

    return () => {
      clearInterval(interval);
      clearInterval(pollingIntervalRef.current);
    };
  }, [chat_id]);

  useEffect(() => {
    if (showRightSidebar) {
      getDeviceInfo();
    }
  }, [showRightSidebar]);

  const getDeviceInfo = async () => {
    const newDeviceInfo = { ...deviceInfo };
    newDeviceInfo["device_model"] = newDeviceInfo["device_model"][0];
    const data = await getDeviceGlossary(newDeviceInfo);
    if (data === null) {
      setDeviceInfoContent("No hay informacion sobre el dispositivo");
    } else {
      setDeviceInfoContent(data);
    }
  };

  const toggleRightSidebar = (state) => {
    if (confirmedByUser) {
      setShowRightSidebar(state);
      if (state && showLeftSidebar) {
        setShowLeftSidebar(false);
      }
    }
  };

  const showRightSidebarReal =
    showRightSidebar && deviceInfoContent ? true : false;

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        handleSend();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [input]);

  const handleUploadImage = async (file) => {
    if (!file) return;

    setLoading(true);

    try {
      await postPhoto(`/chats/${chat_id}/messages/image`, file);
    } catch (error) {
      console.error("Failed to upload image:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleUploadFile = async (file) => {
    if (!file) return;
    setLoading(true);

    const formData = new FormData();
    formData.append("file", file);

    try {
      await postData(`/chats/${chat_id}/messages/file`, formData);
    } catch (error) {
      console.error("Failed to upload file:", error);
    } finally {
      setLoading(false);
    }
  };

  return {
    messages,
    input,
    setInput,
    handleSend,
    toggleRightSidebar,
    showRightSidebarReal,
    deviceInfoContent,
    placeholder,
    setLoading,
    handleUploadAudio,
    handleUploadImage,
    handleUploadFile,
  };
};

export default useChatLogic;
