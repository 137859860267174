import Tesseract from "tesseract.js";

// Tesseract.js OCR as a fallback
const performOCRWithTesseract = async (imageFile) => {
  try {
    const result = await Tesseract.recognize(imageFile, "eng", {
      logger: (info) => console.log(info), // Optional: log Tesseract progress
    });
    const text = result.data.text.toLowerCase();
    console.log("Tesseract OCR Result:", text);
    return text;
  } catch (error) {
    console.error("Error during Tesseract.js OCR:", error);
    return null;
  }
};

// Combine TensorFlow.js and Tesseract.js Results
export const performOCR = async (imageFile) => {
  let result = "";

  // Fallback to Tesseract.js
  const tesseractResult = await performOCRWithTesseract(imageFile);
  if (tesseractResult) {
    result += ` ${tesseractResult}`;
  }

  console.log("Final OCR Result:", result.trim());
  return result.trim();
};
